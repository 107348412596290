export const environment = {
    app: 'zshop',
    configs: {
        indexRoute: 'shopping',
        defaultRoute: 'applications',
        useLocalConfig: useLocalConfig,
    },
    production: false,
    squidex: {
        baseUrl: 'https://squidex-production.i1.zipari.cloud',
    },
};
